/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-xls': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM6.472 15.29a1.2 1.2 0 01-.111-.449h.765a.58.58 0 00.254.384q.106.073.25.114.143.041.319.041.246 0 .413-.07a.56.56 0 00.255-.193.5.5 0 00.085-.29.39.39 0 00-.153-.326q-.152-.12-.462-.193l-.619-.143a1.7 1.7 0 01-.539-.214 1 1 0 01-.351-.367 1.1 1.1 0 01-.123-.524q0-.366.19-.639.19-.272.527-.422.338-.15.777-.149.457 0 .78.152.324.153.5.41.18.255.2.566h-.75a.56.56 0 00-.12-.258.6.6 0 00-.247-.181.9.9 0 00-.369-.068q-.325 0-.513.152a.47.47 0 00-.184.384q0 .18.143.3a1 1 0 00.405.175l.62.143q.326.075.566.211a1 1 0 01.375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 01-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 01-.478-.252 1.1 1.1 0 01-.29-.375m-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94zm2.727 3.325H4.557v-3.325h-.79v4h2.487z"/>',
    },
});
